import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, ButtonToolbar, Col, Container, Form, Row, Spinner, Modal, Card, ListGroup, Dropdown } from 'react-bootstrap';
import moment from 'moment';
// @ts-ignore
import DualListBox from 'react-dual-listbox';

import FormHeader from '../clients/components/forms/FormHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import Table from '../../components/Table';
import { CurrencyFormatter } from '../../components/Formatters';
import { EMafTransaction, EMafTransactionDetails, ReportDownloadType } from '../../models/Reports';
import { getBatchReport, getBatchReportDetails } from '../../redux/actions/reports/eMaf';
import { IActionResult, IAppState } from '../../redux/storeTypes';
import PageHeader from "../../components/layout/PageHeader";
import { Crumb } from "../../models/Crumb";
import { Routes } from "../../routes";
import { CLEAR_DOCUMENT, createCSV, createPDF, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS } from "../../redux/actions/document";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom, faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { sendErrorToastAction } from "../../redux/actions/toast";
import { Client } from "../../models/Client";
import { getClientsAction } from "../../redux/actions/clients/clients";

export interface eMafReportProps {
    isFetching: boolean,
    isFetchingDetails: boolean,
    clients: Array<Client>,
    actionResult: IActionResult,
    items: Array<EMafTransaction>,
    itemDetails: Array<EMafTransactionDetails>,
    documentActionResult: IActionResult,
    blobObject?: Blob
}

export const downloadFile = (blobObject: Blob, fileName: string, ext: string) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blobObject);
    link.setAttribute('download', `${fileName}.${ext}`);

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};

const EMaf = ({ isFetching, clients, items, isFetchingDetails, itemDetails, actionResult, documentActionResult, blobObject }: eMafReportProps) => {
    const dispatch = useDispatch();

    const defaultStartDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const defaultEndDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const defaultBatchId = "";
    const defaultMerchantNumber = "";
    const defaultBatchType = "";
    const defaultDepartmentIds = new Array<string>();

    const [startDate, setStartDate] = useState<string>(defaultStartDate);
    const [endDate, setEndDate] = useState<string>(defaultEndDate);
    const [batchId, setBatchId] = useState<string>(defaultBatchId);
    const [merchantNumber, setMerchantNumber] = useState<string>(defaultMerchantNumber);
    const [batchType, setBatchType] = useState<string>(defaultBatchType);
    const [departmentIds, setDepartmentIds] = useState<Array<string>>(defaultDepartmentIds);

    const [waitingForDownload, setWaitingForDownload] = useState<boolean>(false);

    const [showModal, setModal] = useState(false);
    const [details, setDetails] = useState<EMafTransaction>();

    const emafPdfCreationActionToken = "emafPdfCreationActionToken";
    const emafCsvCreationActionToken = "emafCsvCreationActionToken";
 
    useEffect(() => {
        dispatch(getClientsAction(1, 100));
        dispatch(getBatchReport(startDate, endDate, batchId, merchantNumber, batchType, "getEMafBatchReport"));
    }, []);

    useEffect(() => {
        if (documentActionResult && documentActionResult.result) {
            if (documentActionResult.type === CREATE_DOCUMENT_REQUEST && documentActionResult.token === emafPdfCreationActionToken) {
                if (documentActionResult.result === CREATE_DOCUMENT_SUCCESS && !!blobObject) {
                    let fileName = `eMaf_${startDate}-${endDate}`;
                    downloadFile(blobObject, fileName, "pdf");
                } else if (documentActionResult.result === CREATE_DOCUMENT_FAILURE) {
                    dispatch(sendErrorToastAction("PDF creation failed"));
                }
                setWaitingForDownload(false);
            }

            if (documentActionResult.type === CREATE_DOCUMENT_REQUEST && documentActionResult.token === emafCsvCreationActionToken) {
                if (documentActionResult.result === CREATE_DOCUMENT_SUCCESS && !!blobObject) {
                    let fileName = `eMaf_${startDate}-${endDate}`;
                    downloadFile(blobObject, fileName, "csv");
                } else if (documentActionResult.result === CREATE_DOCUMENT_FAILURE) {
                    dispatch(sendErrorToastAction("CSV creation failed"));
                }
                setWaitingForDownload(false);
            }
        }

        dispatch({ type: CLEAR_DOCUMENT });
    }, [documentActionResult]);

    const resetSearch = () => {
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setBatchId(defaultBatchId);
        setMerchantNumber(defaultMerchantNumber);
        setBatchType(defaultBatchType);
        
        dispatch(getBatchReport(defaultStartDate, defaultEndDate, defaultBatchId, defaultMerchantNumber, defaultBatchType, "getEMafBatchReport"));
    };

    const handleSearch = (event: any) => {
        event.preventDefault();
        setModal(false);

        dispatch(getBatchReport(startDate, endDate, batchId, merchantNumber, batchType, "getEMafBatchReport"));
    };

    // TODO To be used once document api works for emaf urls.
    const doToolbar = () => (
        <Dropdown>
            <Dropdown.Toggle className="approvalAction" variant="outline-secondary" disabled={waitingForDownload}>
                <FontAwesomeIcon icon={faArrowToBottom} size="sm"/> Download Summary
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => {
                    dispatch(createPDF(`MSB_Emaf/api/v1/Emaf/GetBatchReport?fromDate=${startDate}&toDate=${endDate}`, emafPdfCreationActionToken));
                    setWaitingForDownload(true);
                }}>
                    PDF File
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    dispatch(createCSV(`MSB_Emaf/api/v1/Emaf/GetBatchReport?fromDate=${startDate}&toDate=${endDate}`, ReportDownloadType.Summary, emafCsvCreationActionToken));
                    setWaitingForDownload(true);
                }}>
                    CSV File
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

    const columns = [
        {
            dataField: 'merchantName',
            text: 'Merchant',
            editable: false,
            sort: true
        },
        {
            dataField: 'processDate',
            text: 'Process date',
            editable: false,
            sort: true,
            formatter: (date: any) => (<span>{moment(date).format('MM/DD/YYYY')}</span>)
        },
        {
            dataField: 'batchId',
            text: 'Batch ID',
            editable: false,
            sort: true
        },
        {
            dataField: 'batchType',
            text: 'Batch type',
            editable: false,
            sort: true
        },
        {
            dataField: 'saleCount',
            text: 'Sale count',
            editable: false,
            sort: true
        },
        {
            dataField: 'saleAmount',
            text: 'Sale amount',
            editable: false,
            formatter: CurrencyFormatter
        },
        {
            dataField: 'returnReversalCount',
            text: 'Return/Reversals count',
            editable: false
        },
        {
            dataField: 'returnReversalAmount',
            text: 'Return/Reversals amount',
            editable: false,
            formatter: CurrencyFormatter
        },
        {
            dataField: 'netAmount',
            text: 'Net amount',
            editable: false,
            sort: true,
            formatter: CurrencyFormatter
        },
        {
            text: 'Actions',
            editable: false,
            formatter: (_cell: any, row: any) => (<span><a onClick={() => setDetails(row)}>View Details</a></span>)
        }
    ];

    if (details) {
        return (
            <EMafDetails eMafReport={details} isFetchingDetails={isFetchingDetails} actionResult={actionResult} eMafReportDetails={itemDetails} />
        );
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("eMAF Report", Routes.EMafReport.path));

        return (
            <>
                <PageHeader title={`eMAF Report`} crumbs={crumbs} />

                <Container fluid className="container-table-search">
                    <PageSectionContainer>
                        <Form className="table-search advanced-search" onSubmit={handleSearch}>
                            <Row>
                                <Col md={4} sm={4}>
                                    <Form.Group controlId="startDate">
                                        <Form.Label>Start date</Form.Label>
                                        <Form.Control type="date" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4} sm={4}>
                                    <Form.Group controlId="endDate">
                                        <Form.Label>End date</Form.Label>
                                        <Form.Control type="date" name="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={4} sm={4}>
                                    <Form.Group controlId="batchId" style={{ marginBottom: '1rem' }}>
                                        <Form.Label>Batch ID</Form.Label>
                                        <Form.Control type="input" name="batchId" value={batchId} onChange={(e) => setBatchId(e.target.value)} placeholder="Enter Batch ID" />
                                    </Form.Group>
                                </Col>
                                <Col md={12} sm={12}>
                                    <ButtonToolbar>
                                        <Button variant="link" onClick={() => setModal(true)}>Advanced Search</Button>
                                        <Button variant="outline-secondary" onClick={resetSearch}>Reset</Button>
                                        <Button type="submit">Search</Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Form>
                    </PageSectionContainer>
                </Container>
    
                <Container fluid>
                    <PageSectionContainer title="Manage eMAF Report" toolbar={doToolbar()}>
                        {
                            isFetching ?
                            <Spinner animation="border" /> :
                            (
                              items.length < 1 ?
                              <>
                                <h2 className="fw-bold">No records were found</h2>
                                <span>Please do another search to find the record you are looking for.</span>
                              </>
                              :
                              <Table
                                  keyField="eMafTransactions"
                                  data={items}
                                  columns={columns}
                              /> 
                            )
                        }
                        
                    </PageSectionContainer>
                </Container>
            
                <Modal show={showModal} onHide={setModal} size="lg" className="advanced-search-modal">
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <FormHeader title="Advanced Search" description="Please enter the details below to narrow your search results" />
                        <Form className="advanced-search-form" onSubmit={handleSearch}>
                            <div className="advanced-search-form-columns">
                                <Form.Group controlId="startDate">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="endDate">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="merchantNumber">
                                    <Form.Label>Merchant number</Form.Label>
                                    <Form.Control type="input" name="merchantNumber" value={merchantNumber} placeholder="Enter Merchant number" onChange={(e) => setMerchantNumber(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="batchType">
                                    <Form.Label>Batch type</Form.Label>
                                    <Form.Control as="select" value={batchType} onChange={(e) => setBatchType(e.target.value)}>
                                        <option key={0} value="">All Batch types</option>
                                        <option key={1} value="debit">Debit</option>
                                        <option key={2} value="credit">Credit</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            { 
                                clients &&
                                <DualListBox
                                    selected={departmentIds}
                                    options={
                                        clients.map(client => ({
                                            label: client.businessName,
                                            options: (client.departments || []).map(department => ({
                                                label: department.name,
                                                value: department.msbId
                                            }))
                                        }))
                                    }
                                    onChange={(ids: any) => setDepartmentIds(ids)}
                                    className="transfer"
                                    showHeaderLabels={true}
                                    lang={{
                                        availableHeader: 'All departments',
                                        selectedHeader: 'Selected departments'
                                    }}
                                    icons={{
                                        moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                                        moveAllLeft: [
                                            <FontAwesomeIcon icon={faChevronLeft} key={0} />,
                                            <FontAwesomeIcon icon={faChevronLeft} key={1} />,
                                        ],
                                        moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                                        moveAllRight: [
                                            <FontAwesomeIcon icon={faChevronRight} key={0} />,
                                            <FontAwesomeIcon icon={faChevronRight} key={1} />,
                                        ],
                                    }}
                                />
                            }

                            <div className="advanced-search-footer">
                                <Button type="submit">
                                    Search
                                </Button>
                                <Button form='navientUserSubmit' className="navientUserFooterCancel" variant="link" onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
};

const EMafDetails = ({ eMafReport, isFetchingDetails, actionResult, eMafReportDetails }: { 
    eMafReport: EMafTransaction,
    isFetchingDetails: boolean,
    actionResult: IActionResult,
    eMafReportDetails: Array<EMafTransactionDetails> 
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBatchReportDetails(eMafReport.processDate, eMafReport.merchantNumber, eMafReport.batchId, "getEMafBatchReportDetails"));
    }, []);

    const columns = [
        {
            dataField: 'merchantName',
            text: 'Merchant name',
            editable: false,
            sort: true
        },
        {
            dataField: 'processDate',
            text: 'Process date',
            editable: false,
            sort: true,
            formatter: (date: any) => (<span>{moment(date).format('MM/DD/YYYY')}</span>)
        },
        {
            dataField: 'transactionDateTime',
            text: 'Transaction date',
            editable: false,
            sort: true,
            formatter: (date: any) => (<span>{moment(date).format('MM/DD/YYYY')}</span>)
        },
        {
            dataField: 'cardType',
            text: 'Card type',
            editable: false,
            sort: true
        },
        {
            dataField: 'entryType',
            text: 'Entry mode',
            editable: false,
            sort: true
        },
        {
            dataField: 'transactionType',
            text: 'Transaction type',
            editable: false,
            sort: true
        },
        {
            dataField: 'avsResponseCode',
            text: 'AVS response code',
            editable: false,
            formatter: (code: string) => (<span>{ !!code ? code : "N/A" }</span>)
        },
        {
            dataField: 'cardAccountNumber',
            text: 'Card account number',
            editable: false
        },
        {
            dataField: 'authCode',
            text: 'Authorization code',
            editable: false
        },
        {
            dataField: 'amount',
            text: 'Subtotal',
            editable: false,
            formatter: CurrencyFormatter
        },
        {
            dataField: 'acquirerRefNumber',
            text: 'Acquirer reference number',
            editable: false,
            formatter: (ref: string) => (<span>{ !!ref ? ref : "N/A" }</span>)
        },
        {
            dataField: 'merchantNumber',
            text: 'Merchant number',
            editable: false
        }
    ];

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("eMAF Report", Routes.EMafReport.path));
    crumbs.push(new Crumb(`${eMafReport.merchantName} Details`, Routes.EMafReport.path)); 
    // TODO: The View Details screen routing will need updated so the breadcrumb will work properly and match other transaction/payment details screens.
    return (
      <>
        <PageHeader title={`${eMafReport.merchantName} Details`} crumbs={crumbs} />
        
        <Container fluid className="container-payment-details">
        
          <PageSectionContainer>
            <div className="container-transaction-details">
              <div className="transactionTitle">
                  <h2>{eMafReport.merchantName} Details</h2>
                  <p>Below are the report details.</p>
              </div>

              <div className="transactionList">
                <ListGroup variant="flush">
                  <ListGroup.Item className="p-0">
                    <div className="transactionDetail">
                      <h3>Merchant</h3>
                      {eMafReport.merchantName}
                    </div>
                    <div className="transactionDetail">
                      <h3>Process date</h3>
                      {moment(eMafReport.processDate).format('MM/DD/YYYY')}
                    </div>
                    <div className="transactionDetail">
                      <h3>Batch ID</h3>
                      {eMafReport.batchId}
                    </div>
                    <div className="transactionDetail">
                      <h3>Batch type</h3>
                      {eMafReport.batchType}
                    </div>
                    <div className="transactionDetail">
                      <h3>Sale count</h3>
                      {eMafReport.saleCount}
                    </div>
                    <div className="transactionDetail">
                      <h3>Sale amount</h3>
                      {CurrencyFormatter(eMafReport.saleAmount)}
                    </div>
                    <div className="transactionDetail">
                      <h3>Return/Reversal count</h3>
                      {eMafReport.returnReversalCount}
                    </div>
                    <div className="transactionDetail">
                      <h3>Return/Reversal amount</h3>
                      {CurrencyFormatter(eMafReport.returnReversalAmount)}
                    </div>
                    <div className="transactionDetail">
                      <h3>Cash advance count</h3>
                      {eMafReport.cashAdvanceCount}
                    </div>
                    <div className="transactionDetail">
                      <h3>Cash advance amount</h3>
                      {CurrencyFormatter(eMafReport.cashAdvanceAmount)}
                    </div>
                    <div className="transactionDetail">
                      <h3>Net count</h3>
                      {eMafReport.netCount}
                    </div>
                    <div className="transactionDetail">
                      <h3>Net amount</h3>
                      {CurrencyFormatter(eMafReport.netAmount)}
                    </div>
                    <div className="transactionDetail">
                      <h3>Chain name</h3>
                      {eMafReport.chainName}
                    </div>
                    <div className="transactionDetail">
                      <h3>Store number</h3>
                      {eMafReport.storeNumber}
                    </div>
                    <div className="transactionDetail">
                      <h3>Merchant number</h3>
                      {eMafReport.merchantNumber}
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>

              <hr />

              <Card className="cf-card" style={{ paddingBottom: '16px' }}>
                <Card.Header>Transactions</Card.Header>
                <Card.Body>
                  {isFetchingDetails ? (
                    <Spinner animation={"border"} />
                  ) : (
                    <>
                      {(eMafReportDetails || []).length < 1 ? (
                        <>
                          <h2 className="fw-bold">No records were found</h2>
                          <span>Please do another search to find the record you are looking for.</span>
                        </>
                      ) : (
                        <Table
                          keyField="transactionDateTime"
                          columns={columns}
                          data={eMafReportDetails} />
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </div>

          </PageSectionContainer>
        </Container>
      </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        clients: state.clients.currentPage?.data,
        documentActionResult: state.document.actionResult,
        blobObject: state.document.blobObject,
        isFetching: state.eMafTransactions.isFetching,
        isFetchingDetails: state.eMafTransactions.isFetchingDetails,
        actionResult: state.eMafTransactions.actionResult,
        items: state.eMafTransactions.items,
        itemDetails: state.eMafTransactions.itemDetails
    };
};

export default connect(mapStateToProps)(EMaf);