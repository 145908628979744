import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, ButtonToolbar, Dropdown, Col, Container, Form, Row, Spinner, Modal } from 'react-bootstrap';
import { Client, CustomerFundingType, DebitFeeMethodEnum } from '../../models/Client';
import { CurrencyFormatter } from '../../components/Formatters';
import { Redirect } from 'react-router-dom';
import { Routes } from "../../routes";
import { MsbReportItem } from "../../models/Reports";
import { IAppState, IActionResult } from "../../redux/storeTypes";
import { GET_ORDER_TRX_DETAILS_FAILURE, GET_ORDER_TRX_DETAILS_REQUEST, GET_ORDER_TRX_DETAILS_SUCCESS, getFundingReport, getFundingReportResults } from "../../redux/actions/reports/report";
import { TransactionTypeEnum, PaymentType } from "../../models/Payment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom, faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
// @ts-ignore
import DualListBox from 'react-dual-listbox';
import moment from 'moment';
import PageSectionContainer from "../../components/layout/PageSectionContainer";
import FormHeader from '../clients/components/forms/FormHeader';
import PageHeader from "../../components/layout/PageHeader";
import { Crumb } from "../../models/Crumb";
import _ from "lodash";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import PerfectScrollbar from 'react-perfect-scrollbar';
import BasicTableWithProps from "../../components/TableProviderKit";
import { rowStyle } from "./financeACH/fundByTimeZoneACH";
import { exportCSVData } from "./components/FundingCSVExport";
import { getAllPaymentChannelNamesAction } from "../../redux/actions/clients/paymentChannels";
import { getClientNamesAction, getClientsAction } from "../../redux/actions/clients/clients";
import { getDepartmentNamesAction } from "../../redux/actions/clients/departments";
import { User, UserTypeEnum } from "../../models/User";

export interface FundingReportProps {
    clients: Array<Client>,
    isFetching: boolean,
    items: Array<any>,
    orderTransactions: any,
    clientNames?: any,
    departmentNames?: any,
    paymentChannelNames?: any,
    currentUser?: User,
    actionResult?: IActionResult,
    clientTimeZones?: any
}

const FundingReport = ({ clients, isFetching, items, orderTransactions, clientNames, departmentNames, paymentChannelNames, currentUser, actionResult, clientTimeZones }: FundingReportProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const actionToken = "GetFundingBatchReport";

    const defaultStartDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const defaultEndDate = moment().format('YYYY-MM-DD');

    const [startDate, setStartDate] = useState<string>(defaultStartDate);
    const [endDate, setEndDate] = useState<string>(defaultEndDate);
    const [debitFeeMethod, setDebitFeeMethod] = useState<DebitFeeMethodEnum>();
    const [departmentIds, setDepartmentIds] = useState<Array<string>>([]);

    const [data, setData] = useState<Array<any>>([]);
    const [selectedRow, setSelectedRow] = useState<any>();

    const [showDateError, setShowDateError] = useState<boolean>(false);
    const [showModal, setModal] = useState(false);

    const buttonRef = useRef<any>(null)

    const totalFundedFormatter = (_cell: any, row: any, isCSV?: boolean) => {
        return CurrencyFormatter(row.totalCredits + row.totalDebits, isCSV)
    }

    const actionsFormatter = (cell: any, row: any) => {
        return (
            <Button variant="link" style={{ padding: 0 }} onClick={() => { callOrderIdentifier(row) }} disabled={row?.disabled}>
                <div style={{ display: 'inline-flex', columnGap: '4px' }}>
                    {row?.selected && <Spinner animation="border" />} Download Details
                </div>
            </Button>
        )
    }

    const callOrderIdentifier = (row: any) => {
        setSelectedRow(row);
        const modifiedDataLoading = data.map((details: any) => details.reportId === row.reportId ? { ...details, selected: true, disabled: true } : { ...details, selected: false, disabled: true })
        setData(modifiedDataLoading);
        const orderId = [{ orderIdentifier: row?.orderIdentifiers[0]?.orderIdentifier, transactionType: row?.orderIdentifiers[0]?.transactionType }];
        const multipleOrderIds = row?.orderIdentifiers?.map((orderId: any) => { return { orderIdentifier: orderId?.orderIdentifier, transactionType: orderId?.transactionType } });
        const orderIdentifiers = row?.orderIdentifiers?.length > 1 ? multipleOrderIds : orderId;
        dispatch(getFundingReportResults(orderIdentifiers, moment(row?.transactionDate).format('MM-DD-YYYY'), clientTimeZones, ''));
    }

    const totalCountFormatter = (cell: any, row: any, isCSV?: boolean) => {
        const filteredCount = cell.filter((item: any) => item?.paymentType !== PaymentType.Cash && item?.paymentType !== PaymentType.PhysicalCheck).length;
        return isCSV ? filteredCount : <>{filteredCount}</>;
    }

    const debitFeeFormatter = (cell: any, row: any, isCSV?: boolean) => {
        const debitFeeMethodEnums = _.uniq(cell);
        const debitFeeMethods = debitFeeMethodEnums.map((method: any) => { return DebitFeeMethodEnum[method] }).join(", ")
        return (isCSV ? debitFeeMethods : <>{debitFeeMethods}</>)
    }

    const customerFundingFormatter = (cell: any, row: any, isCSV?: boolean) => {
        const customerFundingEnums = _.uniq(cell);
        const customerFunding = customerFundingEnums.map((method: any) => { return CustomerFundingType[method] }).join(", ")
        return (isCSV ? customerFunding : <>{customerFunding}</>)
    }

    const defaultSorted = [{
        dataField: 'transactionDate',
        order: 'desc'
    }];

    const columns = [
        {
            dataField: 'clientName',
            text: 'Client',
            editable: false,
            sort: true
        },
        {
            dataField: 'departmentName',
            text: 'Department',
            editable: false,
            sort: true
        },
        {
            dataField: 'customerFunding',
            text: 'Funding type',
            editable: false,
            formatter: (cell: any, row: any) => customerFundingFormatter(cell, row),
            csvFormatter: (cell: any, row: any) => customerFundingFormatter(cell, row, true)
        },
        {
            dataField: 'transactionDate',
            text: 'Transaction date',
            editable: false,
            sort: true,
            formatter: (cell: any) => moment(cell).format('MM-DD-YYYY') || '',
            csvFormatter: (cell: any) => moment(cell).format('MM-DD-YYYY') || ''
        },
        {
            dataField: 'transactionItems',
            text: 'Transaction count',
            editable: false,
            sort: true,
            formatter: (cell: any, row: any) => totalCountFormatter(cell, row),
            csvFormatter: (cell: any, row: any) => totalCountFormatter(cell, row, true)
        },
        {
            dataField: 'totalCredits',
            text: 'Total credits',
            editable: false,
            formatter: (cell: any) => CurrencyFormatter(cell),
            csvFormatter: (cell: any) => CurrencyFormatter(cell, true)
        },
        {
            dataField: 'totalDebits',
            text: 'Total debits',
            editable: false,
            formatter: (cell: any) => CurrencyFormatter(cell),
            csvFormatter: (cell: any) => CurrencyFormatter(cell, true)
        },
        {
            dataField: 'totalFunded',
            text: 'Difference',
            editable: false,
            formatter: (cell: any, row: any) => totalFundedFormatter(cell, row),
            csvFormatter: (cell: any, row: any) => totalFundedFormatter(cell, row, true)
        },
        {
            dataField: 'debitFeeMethod',
            text: 'Debit fee method',
            editable: false,
            formatter: (cell: any, row: any) => debitFeeFormatter(cell, row),
            csvFormatter: (cell: any, row: any) => debitFeeFormatter(cell, row, true)
        },
        {
            dataField: 'reportId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
            csvExport: false
        },
    ];

    useEffect(() => {
        if (items) {
            const transactionTypes = [TransactionTypeEnum.Sale, TransactionTypeEnum.Refund, TransactionTypeEnum.Return, TransactionTypeEnum.Chargeback, TransactionTypeEnum.AuthorizationCommit, TransactionTypeEnum.ChargebackReversal];
            const groupedItems = items.reduce((result: any, item: any) => {
                if (!transactionTypes.includes(item.transactionType)) {
                    return result;
                }
                const key = item?.departmentId + item?.transactionDate;
                if (!result[key]) {
                    result[key] = {
                        reportId: item?.id,
                        clientId: item?.clientId,
                        clientName: item.clientName,
                        departmentId: item?.departmentId,
                        departmentName: item.departmentName,
                        paymentChannelId: item?.paymentChannelId,
                        paymentChannelName: item?.paymentChannelName,
                        debitFeeMethod: [],
                        customerFunding: [],
                        transactionDate: item.transactionDate,
                        totalCount: 0,
                        totalCredits: 0,
                        totalDebits: 0,
                        transactionItems: [],
                        orderIdentifiers: [],
                        transactionDates: [],
                        paymentType: item?.paymentType
                    };
                }
                result[key].totalCredits +=
                    (item?.paymentType !== PaymentType.Cash && item?.paymentType !== PaymentType.PhysicalCheck &&
                        (item.transactionType === TransactionTypeEnum.Sale || item.transactionType === TransactionTypeEnum.ChargebackReversal || item.transactionType === TransactionTypeEnum.AuthorizationCommit))
                        ? item.amount : 0;
                result[key].totalDebits +=
                    (item?.paymentType !== PaymentType.Cash && item?.paymentType !== PaymentType.PhysicalCheck &&
                        (item.transactionType === TransactionTypeEnum.Refund || item.transactionType === TransactionTypeEnum.Return || item.transactionType === TransactionTypeEnum.Chargeback))
                        ? item.amount : 0;
                result[key].transactionItems.push(item);
                result[key].orderIdentifiers.push({
                    'orderIdentifier': item?.orderIdentifier,
                    'transactionType': item?.transactionType,
                    'customerFunding': CustomerFundingType[item?.customerFundingType],
                    'debitFeeMethod': DebitFeeMethodEnum[item?.debitFeeMethod]
                });
                result[key].debitFeeMethod.push(item?.debitFeeMethod);
                result[key].customerFunding.push(item?.customerFundingType);
                result[key].transactionDates.push(item?.originalPaymentDate);
                return result;
            }, {});
            setData(Object.values(groupedItems));
            if (!paymentChannelNames || !clientNames || !departmentNames) {
                dispatch(getClientNamesAction(actionToken));
                dispatch(getDepartmentNamesAction(actionToken));
                dispatch(getAllPaymentChannelNamesAction(actionToken));
            }
        }
    }, [items]);

    useEffect(() => {
        if (orderTransactions?.length > 0) {
            console.log(orderTransactions)
            const modifiedCSVData = exportCSVData(orderTransactions, selectedRow, clientNames, departmentNames, paymentChannelNames);
        }
    }, [orderTransactions]);

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === GET_ORDER_TRX_DETAILS_REQUEST) {
            if (actionResult.result === GET_ORDER_TRX_DETAILS_FAILURE || actionResult.result === GET_ORDER_TRX_DETAILS_SUCCESS) {
                const dataLoaded = data.map((details: any) => details.reportId === selectedRow.reportId ? { ...details, selected: false, disabled: false } : { ...details, selected: false, disabled: false })
                setData(dataLoaded);
            }
        }
    }, [actionResult]);
    useEffect(() => {
        if (!clients) {
            dispatch(getClientsAction(1, 1000));
        }
    }, [clients]);

    const doToolbar = () => {
        if (!isFetching && data && data.length > 0 && data[0] != '') {
            return (
                <div className="flexContainer">
                    <div>
                        <Button variant="outline-secondary" onClick={() => buttonRef.current.click()} disabled={isFetching}>
                            {isFetching && <Spinner animation="border" />}
                            <FontAwesomeIcon icon={faArrowToBottom} size="sm" style={{ marginRight: '12px' }} /> Download CSV
                        </Button>
                    </div>
                </div>
            )
        }
        else {
            return (<></>);
        }
    }

    const resetSearch = () => {
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setDebitFeeMethod(undefined);
        setDepartmentIds([]);
        const defaultStartDateMinus1 = moment(defaultStartDate).subtract('1', 'days').format('YYYY-MM-DD');
        const defaultEndDateMinus1 = moment(defaultEndDate).subtract('1', 'days').format('YYYY-MM-DD');
        dispatch(getFundingReport(defaultStartDateMinus1, defaultEndDateMinus1, [], actionToken));
    };

    const handleSearch = (event: any) => {
        event.preventDefault();
        if (moment(endDate).isSame(startDate) || moment(endDate).isAfter(moment(startDate))) {
            setModal(false);
            const startDateMinus1 = moment(startDate).subtract('1', 'days').format('YYYY-MM-DD');
            const endDateMinus1 = moment(endDate).subtract('1', 'days').format('YYYY-MM-DD');
            dispatch(getFundingReport(startDateMinus1, endDateMinus1, departmentIds, actionToken));
        }
    };

    const handleStartDateChange = (date: string) => {
        setStartDate(date);
        setShowDateError(!moment(endDate).isSame(date) && !moment(endDate).isAfter(moment(date)));
    };

    const handleEndDateChange = (date: string) => {
        setEndDate(date);
        setShowDateError(!moment(date).isSame(startDate) && !moment(date).isAfter(moment(startDate)))
    };

    const statusBarTotalCredits = items.reduce((acc, cur) => {
        if (cur.paymentType !== PaymentType.Cash &&
            cur.paymentType !== PaymentType.PhysicalCheck &&
            (cur.transactionType === TransactionTypeEnum.Sale || cur.transactionType === TransactionTypeEnum.AuthorizationCommit || cur.transactionType === TransactionTypeEnum.ChargebackReversal)
        ) {
            return acc + cur.amount
        } else {
            return acc
        }
    }, 0);

    const statusBarTotalDebits = items.reduce((acc, cur) => {
        if (cur.paymentType !== PaymentType.Cash &&
            cur.paymentType !== PaymentType.PhysicalCheck &&
            (cur.transactionType === TransactionTypeEnum.Refund ||
                cur.transactionType === TransactionTypeEnum.Return ||
                cur.transactionType === TransactionTypeEnum.Chargeback)
        ) {
            return acc + cur.amount
        } else {
            return acc
        }
    }, 0);

    const statusBarTotalFunded = items.reduce((acc, cur) => {
        if (cur.paymentType !== PaymentType.Cash &&
            cur.paymentType !== PaymentType.PhysicalCheck &&
            [TransactionTypeEnum.Sale, TransactionTypeEnum.Refund, TransactionTypeEnum.Return, TransactionTypeEnum.Chargeback, TransactionTypeEnum.AuthorizationCommit, TransactionTypeEnum.ChargebackReversal].includes(cur.transactionType)) {
            return acc + cur.amount
        } else {
            return acc;
        }
    }, 0);

    const ExportCSV = (props: any) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <div className="flexContainer">
                <div>
                    <Button hidden={true} ref={buttonRef} variant="outline-secondary" onClick={handleClick} disabled={isFetching}>
                        {isFetching && <Spinner animation="border" />}
                        CSV Details
                    </Button>
                </div>
            </div>
        )
    }

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Funding Report", Routes.FundingReport.path));

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <PageHeader title={`Funding Report`} crumbs={crumbs} />
                <Container fluid className="container-table-search">
                    <PageSectionContainer>
                        <Form className="table-search advanced-search" onSubmit={handleSearch}>
                            <Row>
                                <Col xl={4} lg={6} md={6} sm={12}>
                                    <Form.Group controlId="startDate">
                                        <Form.Label>Start date</Form.Label>
                                        <Form.Control type="date" value={startDate} onChange={(e) => handleStartDateChange(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col xl={4} lg={6} md={6} sm={12}>
                                    <Form.Group controlId="endDate">
                                        <Form.Label>End date</Form.Label>
                                        <Form.Control type="date" value={endDate} onChange={(e) => handleEndDateChange(e.target.value)} />
                                    </Form.Group>
                                    <div className={`fundingDateSearchError ${showDateError ? 'd-block' : 'd-none'}`}>
                                        <span style={{ color: "#F5222D", marginTop: "2px", display: "block" }}>End date should be greater than the Start date.</span>
                                    </div>
                                </Col>
                                <Col xl={4} lg={12} md={12} sm={12}>
                                    <ButtonToolbar>
                                        <Button variant="link" onClick={() => setModal(true)}>Advanced Search</Button>
                                        <Button variant="outline-secondary" onClick={resetSearch}>Reset</Button>
                                        <Button type="submit">Search</Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Form>
                    </PageSectionContainer>
                </Container>

                <Container fluid>
                    <PageSectionContainer>
                        <Row>
                            <Col>
                                <div className="report-search-summary">
                                    <h2 className="mb-0">Funding Search Summary</h2>
                                    <span><strong>Total Credits: </strong>{CurrencyFormatter(statusBarTotalCredits)}</span>
                                    <span><strong>Total Debits: </strong>{CurrencyFormatter(statusBarTotalDebits)}</span>
                                    <span><strong>Total Funded: </strong>{CurrencyFormatter(statusBarTotalFunded)}</span>
                                </div>
                            </Col>
                        </Row>
                    </PageSectionContainer>
                </Container>
                <Container fluid>
                    <PageSectionContainer title="Manage Funding Report" toolbar={doToolbar()}>
                        {
                            isFetching ? (
                                <Spinner animation="border" />
                            ) : (
                                data.length < 1 ?
                                    <>
                                        <h2 className="fw-bold">No records were found</h2>
                                        <span>Please do another search to find the record you are looking for.</span>
                                    </> :
                                    <>
                                        <PerfectScrollbar>
                                            <div style={{ paddingBottom: "25px", display: "block", minHeight: "255px" }}>
                                                <ToolkitProvider keyField="reportId" data={data} columns={columns} exportCSV={{ fileName: `Funding Report_${moment(startDate).format('MM-DD-YYYY')}_${moment(endDate).format('MM-DD-YYYY')}.csv` }}>
                                                    {
                                                        (props: any) => (
                                                            <div>
                                                                <ExportCSV {...props.csvProps}>Export CSV!!</ExportCSV>
                                                                <BasicTableWithProps
                                                                    rowStyle={rowStyle}
                                                                    keyField="reportId"
                                                                    columns={columns}
                                                                    defaultSorted={defaultSorted}
                                                                    data={data}
                                                                    {...props.baseProps}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </ToolkitProvider>
                                            </div>
                                        </PerfectScrollbar>
                                    </>
                            )
                        }
                    </PageSectionContainer>
                </Container>

                <Modal show={showModal} onHide={setModal} size="lg" className="advanced-search-modal">
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <FormHeader title="Advanced Search" description="Please enter the details below to narrow your search results" />
                        <Form className="advanced-search-form" onSubmit={handleSearch}>
                            <Row className="advanced-search-form-columns">
                                <Form.Group controlId="startDate">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="endDate">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                </Form.Group>
                            </Row>
                            <Row>
                                {
                                    clients &&
                                    <DualListBox
                                        selected={departmentIds}
                                        canFilter
                                        options={
                                            currentUser?.userClients.map(uc => {
                                                let client = clients.find(c => c.msbId === uc.clientMSBId);
                                                if (client) {
                                                    return {
                                                        label: client?.businessName,
                                                        options: (currentUser.userTypeEnum == UserTypeEnum.Client) ? currentUser?.userDepartments.map(ud => {
                                                            let department = client?.departments.find(d => d.msbId === ud.departmentMSBId);
                                                            if (department) {
                                                                return {
                                                                    label: department?.name, value: department?.msbId
                                                                }
                                                            }
                                                        }).filter(Boolean).sort((a: any, b: any) => a?.label?.localeCompare(b?.label))
                                                            : client?.departments.map(d => { return { label: d.name, value: d.msbId } }).sort((a: any, b: any) => a?.label?.localeCompare(b?.label))
                                                    }
                                                }
                                            }).filter(Boolean).sort((a: any, b: any) => a?.label?.localeCompare(b?.label))
                                        }
                                        onChange={(ids: any) => setDepartmentIds(ids)}
                                        className="transfer"
                                        showHeaderLabels={true}
                                        lang={{
                                            availableHeader: 'All departments',
                                            selectedHeader: 'Selected departments'
                                        }}
                                        icons={{
                                            moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                                            moveAllLeft: [
                                                <FontAwesomeIcon icon={faChevronLeft} key={0} />,
                                                <FontAwesomeIcon icon={faChevronLeft} key={1} />,
                                            ],
                                            moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                                            moveAllRight: [
                                                <FontAwesomeIcon icon={faChevronRight} key={0} />,
                                                <FontAwesomeIcon icon={faChevronRight} key={1} />,
                                            ],
                                        }}
                                    />
                                }
                            </Row>
                            <Row className="advanced-search-footer">
                                <Col>
                                    <Button type="submit">Search</Button>
                                </Col>
                                <Col>
                                    <Button form='navientUserSubmit' className="navientUserFooterCancel" variant="link" onClick={() => setModal(false)}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        clients: state.clients.currentPage?.data,
        currentUser: state.auth.currentUser,
        actionResult: state.reports.actionResult,
        isFetching: state.reports.isFetching,
        items: state.reports.fundingReportTransactions,
        orderTransactions: state.reports.orderDetailsTransactions,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
        paymentChannelNames: state.clients.paymentChannelNames,
        clientTimeZones: state.clients.clientTimezones,
    };
};

export default connect(mapStateToProps)(FundingReport);