export const Routes = {
    // pages
  
    GetUserClient: { path: "/getUserClient" },
  
    PointOfSale: { path: "/pointOfSale" },
    PointOfSaleReceipt: { path: "/pointOfSale/receipt" },
    PrintReceipt: { path: "/printReceipt" },
  
    QuickPay: { path: "/quickpay" },
    QuickPayReceipt: { path: "/quickpay/receipt" },
    TerminalPay: { path: "/terminalPay" },
    TerminalPayReceipt: { path: "/terminalPay/receipt" },
    Approvals: { path: "/approvals" },
  
    SignIn: { path: "/" },
    SignInNavient: { path: "/Navient" },
  
    DashboardOverview: { path: "/dashboard/overview" },
    //Pdf: { path: "/pdf" },
    //Transactions: { path: "/transactions" },
    //Settings: { path: "/settings" },
    //Upgrade: { path: "/upgrade" },
    //BootstrapTables: { path: "/tables/bootstrap-tables" },
    //Billing: { path: "/examples/billing" },
    //Invoice: { path: "/examples/invoice" },
  
    //Signup: { path: "/examples/sign-up" },
    //ForgotPassword: { path: "/examples/forgot-password" },
    //ResetPassword: { path: "/examples/reset-password" },
    //Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },
    GilaSettings: { path: "/gila/settings" },
  
    Clients: { path: "/clients" },
    ClientAdd: { path: "/clients/add" },
    Client: { path: "/client/edit" },
    Fees: { path: "/fees" },
  
    DepartmentAdd: { path: "/clients/department" },
    DepartmentEdit: { path: "/clients/departments/edit" },
  
    MerchantAdd: { path: "/clients/merchant" },
    MerchantEdit: { path: "/clients/merchants/edit" },
  
    AddPMoF: { path: "/PaymentMethodOnFile" },
    RefundPayment: { path: "/RefundPayment" },
    RefundPaymentDetails: { path: "/RefundPayment/Details" },
    VoidPayment: { path: "/VoidPayment" },
    VoidPaymentDetails: { path: "/VoidPayment/Details" },
    PaymentReceipt: { path: "/PaymentReceipt" },
    WebPaymentChannel: { path: "/client/department/paymentChannel/web" },
    WebPaymentChannelPageEditor: { path: "/client/department/paymentChannel/web/page" },
    SiteStyle: { path: "/client/department/paymentChannel/web/components/sections" },
    SiteSettings: { path: "/client/department/paymentChannel/web/components/sections" },
    SiteEditor: { path: "/client/department/paymentChannel/web/components/sections" },
    DepartmentPaymentChannelsSection: { path: "/client/component/manageDepartmentSections" },
    ReceiptEditor: { path: "/client/department/receipt" },
  
    ManagePMoF: { path: "/customer/PMoF" },
  
    ChargebacksReport: { path: "/reports/chargebacks" },
    DeclinedReport: { path: "/reports/declined" },
    DepositsReport: { path: "/reports/deposits" },
    ExceptionsReport: { path: "/reports/exceptions" },
    FundingReport: { path: "/reports/funding" },
    FundingDetails: { path : "/reports/funding/details" },
    FundingTransactions: { path : "/reports/funding/transactions" },
    InvoicesReport: { path: "/reports/invoices" },
    PaymentsReport: { path: "/reports/payments" },
    PendingPaymentsReport: { path: "/reports/pendingpayments" },
    RefundsReport: { path: "/reports/refunds" },
    ShoppingCartReport: { path: "/reports/shoppingcart" },
    TransactionsReport: { path: "/reports/transactions" },
    TrendingDataReport: { path: "/reports/trendingdata" },
    VoidsReport: { path: "/reports/voids" },
    AuthorizationsReport: { path: "/reports/authorizations" },
    ViewDetails: { path: "/reports/viewdetails" },
  
    ComplianceExceptions: { path: "/audit/complianceExceptions" },
    ConvenienceFee: { path: "/audit/convenienceFee" },
    FeeAudit: { path: "/audit/feeAudit" },
    NoConvenienceFee: { path: "/audit/noConvenienceFee" },
  
    CreateChargeback: { path: "/finance/CreateChargeback" },
    CreateChargebackDetails: { path: "/finance/CreateChargeback/Details" },
    ChargebackReversals: { path: "/finance/ChargebackReversals" },
    ChargebackReversalDetails: { path: "/finance/ChargebackReversal/Details" },
    EMafReport: { path: "/finance/emaf" },
    ReconciliationReport: { path: "/finance/reconciliations" },
    ReconciliationReportDetails: { path: "/finance/reconciliations/details" },
    ReconciliationReportTransactions: { path: "/finance/reconciliations/transactions" },
    WorldpayTransactionsReport: { path: "/finance/WorldpayTransactions" },
    WorldpayTransactionsReportDetails: { path: "/finance/WorldpayTransactions/Details" },
    InvoiceReport: { path: "/finance/InvoiceReportTransactions" },
    ClientBanking: { path: "/finance/ClientBanking"},
    ACHTimezoneReport: { path: "/finance/ACHTimezone"},
  
    NavientUsers: { path: "/access/navient/users" },
    AddNavientUsers: { path: "/access/navient/user" },
    EditNavientUsers: { path: "/access/navient/user/edit" },
    NavientRoles: { path: "/access/navient/roles" },
    AddNavientRoles: { path: "/access/navient/role" },
    EditNavientRoles: { path: "/access/navient/role/edit" },
  
    ClientUsers: { path: "/access/client/users" },
    AddClientUser: { path: "/access/client/users/add" },
    EditClientUser: { path: "/access/client/user/edit" },
    ClientRoles: { path: "/access/client/roles" },
    AddClientRoles: { path: "/access/client/role" },
    EditClientRoles: { path: "/access/client/role/edit" },
  
    AccountSettings: { path: "/account/settings" },
  
    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" },
  };
  